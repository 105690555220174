// stores/competitionStore.ts

import { defineStore } from 'pinia'
import type { Competition, ExtendedCompetition, Judge } from '@/interfaces/competition'

import { CompetitionManager } from '@/utils/competitionManager'

import { CompetitionsService } from '@/services/api/isJudging/competitions'
import notify from '@/services/notify'

import { waitForWampConnection, wampCall, wampControllerCall } from '@/services/wamp'

import { useAuthStore } from './authStore'
import { useJudgementStore } from './judgementStore'

export const useCompetitionStore = defineStore('competitionStore', {
  state: () => ({
    competitions: [] as Competition[] | [],
    activeCompetition: null as CompetitionManager | null,
    loading: false as boolean,
    loadingCompetitions: false as boolean,
    loadingActiveCompetition: false as boolean,
    error: null as null | Error,
    initialized: false as boolean
  }),

  getters: {
    getCompetitions: (state) =>
      state.competitions,
    getActiveCompetition: (state) => state.activeCompetition,
    getTeam: (state) => (teamId: number) => {
      return state.activeCompetition?.getTeam(teamId)
    },
    getScoreDetails: (state) => (flightId: string) => {
      return state.activeCompetition?.getFlightInfo(flightId)?.score_details
    }
  },

  actions: {
    async init() {
      if (this.initialized) {
        
        return
      }
      this.loading = true
      try {
        // await waitForWampConnection() // On a plus besoin de wamp pour les competitions
        this.initialized = true
        this.loading = false
        await this.fetchCompetitions()
      } catch (error: any) {
        console.error("Erreur lors de l'initialisation du Competition Store:", error)
        this.error = error
        this.loading = false
        throw error
      }
    },

    async fetchCompetitions() {
      if (useAuthStore().user) {
        this.loadingCompetitions = true

        try {
          const competitions = await CompetitionsService.getCompetitions()

          if (Array.isArray(competitions)) {
            const promises = competitions.map(async (competition) => {
              return {
                ...competition,
              } as Competition
            })
    
            const detailedCompetitions = await Promise.all(promises)
            this.competitions = [] as Competition[]
            this.competitions.push(...detailedCompetitions)

          } else {
            this.error = new Error('competitions is not an array')
          }

        } catch (error: any) {
          notify('Fetching competitions failed', error.message, 'error')
          this.error = error
          throw error
        } finally {
          this.loadingCompetitions = false
        }
      }
      else {
        notify('Fetching competitions failed', 'You are not logged in', 'error')
      }
    },

    async createCompetition(formData: any, image: any) {
      this.loadingCompetitions = true
      const response = await CompetitionsService.createCompetition(formData, image)
      await this.fetchCompetitions()
      this.loadingCompetitions = false
      return response
    },

    async fetchActiveCompetition(competition_id: string) {
      // const startTime = performance.now()
      this.loadingActiveCompetition = true

      const competitionDetails = this.competitions?.find(
        (comp) => String(comp.id) === competition_id
      )

      try {
        let competition_datas = null
        if (competitionDetails) {
          this.activeCompetition = null

          const response = await CompetitionsService.getCompetition(competitionDetails.id)
          const ij_data = response

          competition_datas = {
            ...ij_data,
          }

          this.activeCompetition = new CompetitionManager(competition_datas)
          this.error = ij_data.error

        } else {
          this.activeCompetition = null
          notify('Fetching competition failed', 'Competition not found', 'error')
        }
      } catch (error: any) {
        const errorMessage = error.response?.data?.name?.[0] || error.response?.data?.message || error.response?.data?.non_field_errors?.[0] || error.message || 'Failed to create competition'
        notify('Fetching competition failed', errorMessage, 'error')
        throw new Error(errorMessage)
      } finally {
        this.loadingActiveCompetition = false
        // const endTime = performance.now()
        // const executionTime = endTime - startTime
        // console.log(`fetchActiveCompetition execution time: ${executionTime.toFixed(2)}ms`)
      }
    },

    async refreshCompetitionData() {
      if (!this.activeCompetition) {
        return
      }

      try {
        this.fetchActiveCompetition(
          this.activeCompetition.getCompetitionData().id.toString()
        )
      } catch (error) {
        notify('Fetching competition failed', 'Server Down ? ', 'error')
        this.error = error as Error
        throw error
      }
    },

    async createFlight(flight_id: string) {
      if (!this.activeCompetition) {
        throw new Error('No active competition')
      }

      const flight = this.activeCompetition.getFlightInfo(flight_id)
      if (!flight) {
        throw new Error('Flight not found')
      }

      const eventJudges = (this.activeCompetition
        .getCompetitionData() as ExtendedCompetition)
        .events.find((event) => event.id === flight.event_id)?.judges as Record<
        string,
        { identifier: string }
      >

      const judges: Record<string, string> = {}

      for (let i = 0; i < Object.keys(eventJudges).length; i++) {
        const key = `wm_${i}`
        judges[key] = eventJudges[i].identifier
      }

      const config = {
        event: {
          is_tunn3l_linked: this.activeCompetition.getCompetitionData().is_tunn3l_linked,
          tunn3l_provider: this.activeCompetition.getCompetitionData().tunn3l_provider,
          competition_id: flight.competition_id,
          event_id: flight.event_id
        },
        round_id: flight.round_id,
        battle_id: flight.battle_id,
        round_number: flight.round_number,
        battle_number: flight.battle_number,
        flight_number: flight.number,
        team: flight.team,
        flight_id: flight_id,
        discipline: flight.discipline,
        flight_type: flight.type,
        judges: judges,
        judges_details: eventJudges,
        draw: flight.draw || [],
        ...flight.configuration, // Ajoute toutes les clés/valeurs de flight.configuration
        ...(flight.configuration.bust_value !== undefined && {
          bust: flight.configuration.bust_value // Ajoute bust si bust_value existe
        }),
        ...(flight.configuration.skip_value !== undefined && {
          skip: flight.configuration.skip_value // Ajoute skip si skip_value existe
        }),
        t3_event_id: flight.t3_event_id || null,
        t3_round_id: flight.t3_round_id || null,
        t3_battle_id: flight.t3_battle_id || null,
        t3_team_id: flight.t3_team_id || null,
        comment: flight.comment || null,
      }

      // // wampControllerCall('round_' + flight.flight_type, 'create', configuration)
      // wampCall('competition.dist.round.create', [config])
      
      let controller = ''

      switch (flight.discipline.toLowerCase()) {
        case 'fs4':
        case 'fs8':
        case 'vfs':
          controller = 'relative_work_ng'
          break
        default:
          switch (flight.type.toLowerCase()) {
            case 'speed':
              controller = 'round_speed'
              break
            case 'free':
            case 'compulsory':
              controller = 'round_free'
              break
            default:
              notify('Create flight failed', 'Unknown flight type', 'error')
              throw new Error('Unknown flight type')
          }
      }      
      wampControllerCall(controller, 'create', config)
    },

    async updateFlightScore(flightId: string, score: number) {
      if (!this.activeCompetition) {
        throw new Error('No active competition')
      }
      const flight = this.activeCompetition.getFlightInfo(flightId)
      if (!flight) {
        throw new Error('Flight not found')
      }
      flight.score = score
      
      // update the score in the active competition rawCompetition
      const event = this.activeCompetition.rawCompetition.events.find((event) => event.id === flight.event_id)
      if (event) {
        const round = event.rounds.find((round) => round.id === flight.round_id)
        if (round) {
          // Explicit type conversion since flight IDs in the array are numbers but flightId parameter is a string
          const foundFlight = round.flights.find((f) => String(f.id) === flightId)
          if (foundFlight) {
            foundFlight.score = score
          }
        }
      }
    },

    async createFreeBattleJudgement(eventId: number, roundId: number, battleId: number) {
      if (!this.activeCompetition) {
        throw new Error('No active competition')
      }
      const eventJudges = (this.activeCompetition
        .getCompetitionData() as ExtendedCompetition)
        .events.find((event) => event.id === eventId)?.judges

      const teams = (this.activeCompetition
        .getCompetitionData() as ExtendedCompetition)
        .events.find((event) => event.id === eventId)
        ?.rounds.find((round) => round.id === roundId)
        ?.battles.find((battle) => battle.id === battleId)?.teams

      useJudgementStore().createFreeBattleJudgement(eventId, roundId, battleId, eventJudges, teams)
    }
  }
})
