import { defineStore } from 'pinia'
import { startStopWatch, stopStopWatch, resetStopWatch } from '@/services/stopWatch'

import { wampControllerCall, wampCall } from '@/services/wamp'

import tunn3lApi from '@/services/api/tunn3l/tunn3lApi'
import notify from '@/services/notify'

import { useCompetitionStore } from './competitionStore'

const CONTROLLER_NAME = 'round_speed'

interface ScoreDetail {
  type: string
  name: string
  busts: any[] // You might want to define a more specific type for busts if possible
  bust_ref: string | null
  skipped: boolean | null
  time: number | null
  page?: number
}

// Utilisation du option store
export const useSpeedRoundStore = defineStore('speedRound', {
  state: () => ({
    status: null as string | null,
    trigger: null as string | null,
    configuration: {} as any,
    stopwatch: '0.000' as string,

    // currentBuster: null as [] | null,
    busts: null as [] | null,
    maybusts: null as [] | null,
    skips: null as [] | null,
    moves: null as [] | null,

    times: {} as any,
    exits: null as [] | null,
    metas: {} as any,

    dynamr_flight_id: null as number | null,

    // judges: null as [] | null, // Dans la configuration et en Base ?

    score_details: [] as ScoreDetail[],
    score: null as number | null,
    is_posted: false as boolean,

    current_buster: {} as any,
    current_bust_count: 0,
    current_skip_count: 0,
    current_entry_fault: false,
    current_exit_last: null
  }),
  getters: {
    getScoreDetailsBustsNumber(state): number {
      if (state.score_details === undefined) {
        return 0
      }
      return state.score_details.reduce((acc, detail) => {
        if (detail.bust_ref !== null) {
          return acc + 1
        }
        return acc
      }, 0)
    },
    getScoreDetailsSkipsNumber(state): number {
      if (state.score_details === undefined) {
        return 0
      }
      return state.score_details.reduce((acc, detail) => {
        if (detail.skipped === true) {
          return acc + 1
        }
        return acc
      }, 0)
    }
  },
  actions: {
    reset() {
      ;(this.status = null),
        (this.trigger = null),
        (this.configuration = {}),
        (this.stopwatch = '0.000'),
        // this.currentBuster = null,
        (this.busts = null),
        (this.maybusts = null),
        (this.skips = null),
        (this.moves = null),
        (this.times = {}),
        (this.exits = null),
        (this.metas = {}),
        (this.dynamr_flight_id = null),
        (this.score_details = []),
        (this.score = null),
        (this.is_posted = false),
        (this.current_buster = {}),
        (this.current_bust_count = 0),
        (this.current_skip_count = 0),
        (this.current_entry_fault = false),
        (this.current_exit_last = null)
    },

    fetchAppStatus(data: any, configuration: any, current: any, status: string, trigger: string) {
      this.status = status
      this.trigger = trigger
      this.configuration = configuration

      this.busts = data.busts
      this.maybusts = data.maybusts
      this.skips = data.skips
      this.moves = data.moves
      this.times = data.times
      this.exits = data.exits
      this.metas = data.metas
      this.dynamr_flight_id = data.id

      this.score_details = current.score_details
      this.score = current.score
      this.is_posted = current.is_posted

      this.current_buster = current.current_buster
      this.current_bust_count = current.bust_count
      this.current_skip_count = current.skip_count
      this.current_entry_fault = current.entry_fault
      this.current_exit_last = current.exit_last
    },

    // Set score devrait pas être ici mais dans le server
    setScore() {
      if (this.score_details === undefined || this.times === undefined) {
        this.score = null
      }
      this.score =
        this.times.raw_original +
        this.configuration.bust * this.getScoreDetailsBustsNumber +
        this.configuration.skip * this.getScoreDetailsSkipsNumber
    },

    save(publish: boolean) {
      this.setScore()      
      // wampControllerCall(CONTROLLER_NAME, 'save', {
      //   id: this.dynamr_flight_id,
      //   publish: publish,
      //   score_details: this.score_details,
      //   final_score: this.score
      // })
      
      // TODO : Remplacer par wampCall ?
      const result = wampCall('round_speed.save', [
        this.dynamr_flight_id,
        publish,
        this.score_details,
        this.score
      ])




      // if (publish) {
      //   // On vérifie si un score à déjà été posté
      //   const score = useCompetitionStore().getActiveCompetition?.getFlightInfo(this.configuration.flight_id)?.score
      //   let overwrite = false
      //   if (score) {
      //     overwrite = true
      //   }

      //   this.publishScores(overwrite).then(() => {
      //     useCompetitionStore().refreshCompetitionData()
      //   })
      // } else {
      //   notify('Score saved for later', 'Score have not been uploaded', 'success')
      // }
    },

    async publishScores(overwrite: boolean) {      
      if (this.configuration.event && this.configuration.event.is_tunn3l_linked) {
        try {
          // Upload raw time
          await tunn3lApi.uploadDynamicSpeedRawTime(
            this.configuration.event.tunn3l_provider.url,
            this.configuration.event.tunn3l_provider.auth_token,
            this.configuration.event.tunn3l_provider.t3_competition_token,
            this.configuration.event.tunn3l_provider.t3_competition_id,
            this.configuration.event.event_id,
            this.configuration.round_id,
            this.configuration.battle_id,
            this.configuration.team.id,
            0,
            0,
            this.times.raw_original.toFixed(3),
            0,
            0,
            'time'
          )

          // Upload each penalty (bust and skip)
          for (const [index, detail] of this.score_details.entries()) {
            if (detail.bust_ref) {
              await tunn3lApi.applyBustDynamicSpeed(
                this.configuration.event.tunn3l_provider.url,
                this.configuration.event.tunn3l_provider.auth_token,
                this.configuration.event.tunn3l_provider.t3_competition_token,
                this.configuration.event.tunn3l_provider.t3_competition_id,
                this.configuration.event.event_id,
                this.configuration.round_id,
                this.configuration.battle_id,
                this.configuration.team.id,
                0,
                index + 1, // draw_order starts from 1
                this.configuration.bust_value,
                0,
                0,
                detail.bust_ref.toLowerCase() as 'cl' | 'sl' | 'ri' | 'ex' | 'sy'
              )
            } else if (detail.skipped) {
              await tunn3lApi.applyBustDynamicSpeed(
                this.configuration.event.tunn3l_provider.url,
                this.configuration.event.tunn3l_provider.auth_token,
                this.configuration.event.tunn3l_provider.t3_competition_token,
                this.configuration.event.tunn3l_provider.t3_competition_id,
                this.configuration.event.event_id,
                this.configuration.round_id,
                this.configuration.battle_id,
                this.configuration.team.id,
                0,
                index + 1, // draw_order starts from 1
                this.configuration.skip_value,
                0,
                0,
                'sk'
              )
            } else if (overwrite) {
              // On réinitialise les moves "cleans" dans le cas d'une mise à jour
              await tunn3lApi.applyBustDynamicSpeed(
                this.configuration.event.tunn3l_provider.url,
                this.configuration.event.tunn3l_provider.auth_token,
                this.configuration.event.tunn3l_provider.t3_competition_token,
                this.configuration.event.tunn3l_provider.t3_competition_id,
                this.configuration.event.event_id,
                this.configuration.round_id,
                this.configuration.battle_id,
                this.configuration.team.id,
                0,
                index + 1, // draw_order starts from 1
                0,
                0,
                0,
                '0'
              )
            }
          }

          // A priori pas nécessaire de forcer le final time
          // if (this.score) {
          //   // Upload final time
          //   await tunn3lApi.uploadDynamicSpeedFinalTime(
          //     this.configuration.event.tunn3l_provider.url,
          //     this.configuration.event.tunn3l_provider.auth_token,
          //     this.configuration.event.tunn3l_provider.t3_competition_token,
          //     this.configuration.event.tunn3l_provider.t3_competition_id,
          //     this.configuration.event.event_id,
          //     this.configuration.round_id,
          //     this.configuration.battle_id,
          //     this.configuration.team.id,
          //     0,
          //     0,
          //     parseFloat(this.score.toFixed(3)),
          //     0,
          //     'final_time'
          //   )
          // }

          let message = 'Posted'
          if (overwrite) {
            message = 'Updated'
          }
          
          notify('Scores uploaded to Tunn3l successfully', message, 'success')
        } catch (error: any) {
          notify('Score upload to Tunn3l failed', error.message, 'error')
        }
      }
    },

    updateFlightDetails(moveIndex: number, bust_ref: any | null, skipped: boolean | null) {
      if (bust_ref !== null) {
        if (this.score_details[moveIndex].bust_ref === bust_ref) {
          this.score_details[moveIndex].bust_ref = null
          this.score_details[moveIndex].skipped = null
        } else {
          this.score_details[moveIndex].bust_ref = bust_ref
          this.score_details[moveIndex].skipped = null
        }
      }
      if (skipped !== null) {
        if (this.score_details[moveIndex].skipped === skipped) {
          this.score_details[moveIndex].skipped = false
          this.score_details[moveIndex].bust_ref = null
        } else {
          this.score_details[moveIndex].skipped = skipped
          this.score_details[moveIndex].bust_ref = null
        }
      }
      this.setScore()
    },

    handleEvent(data: any, status: string, trigger: string) {
      const previous_status = this.status

      switch (trigger) {
        case 'create':
          resetStopWatch()
          this.reset()
          this.status = status
          this.trigger = trigger
          this.configuration = data
          this.stopwatch = '0.000'
          break
        case 'activate':
          resetStopWatch()
          this.reset()
          this.status = status
          this.trigger = trigger
          this.configuration = data
          this.stopwatch = '0.000'
          break
        case 'laser_cut':
          if (previous_status === 'active') {
            startStopWatch((time: string) => {
              this.stopwatch = time
            })
          }
          this.status = status
          this.trigger = trigger
          this.current_bust_count = data.bust_count
          this.current_buster = data.current_buster
          this.current_skip_count = data.skip_count
          this.current_entry_fault = data.entry_fault
          this.current_exit_last = data.exit_last
          break
        case 'bust_enter':
        case 'bust_leave':
        case 'skip':
        case 'move_validate':
        case 'maybust':
        case 'move_exit':
          this.status = status
          this.trigger = trigger
          this.current_bust_count = data.bust_count
          this.current_buster = data.current_buster
          this.current_skip_count = data.skip_count
          this.current_entry_fault = data.entry_fault
          this.current_exit_last = data.exit_last
          break
        case 'stop':
          stopStopWatch()
          this.status = status
          this.trigger = trigger
          this.configuration = data.configuration

          this.busts = data.busts
          this.maybusts = data.maybusts
          this.skips = data.skips
          this.moves = data.moves
          this.times = data.times
          this.exits = data.exits
          this.metas = data.metas
          this.dynamr_flight_id = data.id

          this.score_details = data.score_details
          // Upload scores as unofficial // Maybe later
          // if (this.configuration.event) {
          //   this.publishScores().then(() => {
          //     useCompetitionStore().refreshCompetitionData()
          //   })
          // }
          break
      }
    }
  }
})
