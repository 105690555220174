<script setup lang="ts">
import Toaster from '@/components/ui/toast/Toaster.vue'

import { onMounted } from 'vue'
import { RouterView } from 'vue-router'

import TheLeftNavBar from '@/components/nav/TheLeftNavBar.vue'
import TheTopNavBar from '@/components/nav/TheTopNavBar.vue'
import UpdateDynamrLocalModal from '@/components/modals/UpdateDynamrLocalModal.vue'

import { wampConnect, isWampConnected, isWampAvailable } from '@/services/wamp'
import notify from '@/services/notify'

import { useAppStatusStore } from '@/stores/appStatusStore'
import { useAuthStore } from '@/stores/authStore'
import { useThemeStore } from '@/stores/themeStore'

const appStatusStore = useAppStatusStore()
const authStore = useAuthStore()
const themeStore = useThemeStore()
onMounted(async () => {
  try {
    await authStore.checkAuth()
    await wampConnect()
    if (isWampConnected.value === true) {
      await appStatusStore.fetchAppStatus()
      await appStatusStore.subscribe()
    }
  } catch (error) {
    console.error("Erreur lors de l'initialisation:", error)
    notify(
      'Loading Failed',
      'DynamR is not reachable, please check your network and try again.',
      'error'
    )
  }
})
</script>

<template>
  <div id="container" class="flex min-h-screen w-full flex-col bg-background text-foreground">
    <TheLeftNavBar :isWampConnected="isWampConnected" />
    <!-- <div class="flex flex-col sm:gap-4 sm:py-4 sm:pl-14"> -->
    <div class="flex flex-col sm:py-2 sm:pl-14">
      <TheTopNavBar
        :controller="appStatusStore.state.controller"
        :status="appStatusStore.state.status"
        :isWampConnected="isWampConnected"
        :isWampAvailable="isWampAvailable"
      />
      <main
        class="flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8 lg:grid-cols-3 xl:grid-cols-3"
      >
        <RouterView />
      </main>
    </div>
    <!-- <div class="absolute bottom-4 right-4 text-white">
      <span class="sm:hidden">XS</span>
      <span class="hidden sm:block md:hidden">SM</span>
      <span class="hidden md:block lg:hidden">MD</span>
      <span class="hidden lg:block xl:hidden">LG</span>
      <span class="hidden xl:block 2xl:hidden">XL</span>
      <span class="hidden 2xl:block">2XL</span>
    </div> -->
  </div>
  <Toaster />
  <UpdateDynamrLocalModal />
</template>
